import React, { useState } from 'react';
import linkedin from "../../images/icons/linkedin.webp" 

import './ourTeam.less';
import './common.less';

import aboutUsTeam from '../../../services/aboutUsData';
interface TabProps {
    label: string;
    children: React.ReactNode;
  }
  
  const Tab: React.FC<TabProps> = ({ children }) => {
    return <React.Fragment>{children}</React.Fragment>;
  };
  
  interface TabButtonsProps {
    buttons: string[];
    activeTab: string;
    changeTab: (tab: string) => void;
  }
  
  const TabButtons: React.FC<TabButtonsProps> = ({ buttons, activeTab, changeTab }) => {
    return (
      <div className="tab-buttons">
        {buttons.map((button) => {
          return (
            <button className={button === activeTab ? 'active' : ''} onClick={() => changeTab(button)}>
              {button}
            </button>
          );
        })}
      </div>
    );
  };
  
  const Tabs: React.FC = ({ children }) => {
    const [activeTab, setActiveTab] = useState<string>('Country Manager');
  
    const changeTab = (tab: string) => {
      setActiveTab(tab);
    };
  
    let content: React.ReactNode = null;
    let buttons: string[] = [];
  
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        buttons.push(child.props.label);
        if (child.props.label === activeTab) content = child.props.children;
      }
    });
  
    return (
      <div>
        <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab} />
        <div className="tab-content">{content}</div>
      </div>
    );
  };

  function NewTeamCard({ key, img, name, category, linkedin_link }) {
    return (
      <div className="leadership-content" key={key}>
        <div className="leadership-image-container">
          <div className="member-content">
            <span className="img image">
              <span>
                <img loading="lazy" src={img} alt="Images" />
              </span>
            </span>
            <div className="member-content-caption">
              <div className="member-detail-container">
                <h1 className="member-name">{name}</h1>
                <a href={linkedin_link} target="_blank" className="iconImg pl-3">
                  <img loading="lazy" src={linkedin} alt="linkedin icon" />
                </a>
              </div>
              <span
                className="member-designation"
                dangerouslySetInnerHTML={{ __html: category }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    )
  }

const OurTeamNewComp = () => {
  return (
    <div className='OurTeamNew leadershipSection'>
    <div className="tabs">
      <h2>Our Leadership</h2>
      <Tabs>
        <Tab label="Country Manager">
          <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data => data.category === "Country Manager"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
                 </Tab>
        <Tab label="Country Finance Officer">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data => data.category === "Country Finance Officer"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Business Leaders">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Business Leaders"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Marketing and Communications">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Marketing and Communications"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Legal & Compliance">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Legal & Compliance"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Human Resources">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Human Resources"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Information Technology">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Information Technology"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Business Transformation">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Business Transformation"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
        <Tab label="Shared Services Centre">
        <div className="leadership-container">
          {aboutUsTeam
                ?.filter(
                  data =>  data.category === "Shared Services Centre"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
        </div>
        </Tab>
      </Tabs>
    </div>
    </div>
  )
}

export default OurTeamNewComp